import React, { useEffect } from "react";

import Hero from "@components/shared/hero";
import FeatureColumn from "@components/pages-components/features/featureColumn";
import ContactFormWithTitle from "@components/forms/contact-form-with-title";
import CovidResponseBanner from "@components/shared/covidResponseBanner";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { Query, SanityCovid19ResponsePageConnection } from "@graphql-types";
import { PageProps, graphql } from "gatsby";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityCovid19ResponsePageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Covid19Response = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("celos-response-to-covid-19");

  const sanityCovid19ResponsePage =
    props.data.allSanityCovid19ResponsePage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(alt => alt._rawRegion);

  return (
    <>
      <SEO
        seoData={sanityCovid19ResponsePage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        {sanityCovid19ResponsePage.hero && (
          <Hero heroContent={sanityCovid19ResponsePage.hero} />
        )}

        <CovidResponseBanner
          linkData={sanityCovid19ResponsePage.bannerLink}
          noLink
        />

        {sanityCovid19ResponsePage.featureColumnsTop &&
          sanityCovid19ResponsePage.featureColumnsTop.map(feature => {
            return <FeatureColumn key={feature?._key} column={feature} />;
          })}

        <ContactFormWithTitle />
      </LayOut>
    </>
  );
};

export default Covid19Response;

export const query = graphql`
  query covid($iban: String) {
    allSanityCovid19ResponsePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        featureColumnsTop {
          ...sanityImageTextColumn
        }
        bannerLink {
          ...sanityLink
        }
        seo {
          ...sanitySeo
        }
      }
    }
    altRegions: allSanityCovid19ResponsePage {
      nodes {
        _rawRegion(resolveReferences:{maxDepth:10})
      }
    }
  }
`;
