import React from "react";

import { Maybe, SanityLink } from "@graphql-types";
import { TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import styled from "styled-components";

interface Props {
  linkData?: Maybe<SanityLink> | undefined;
  noLink?: boolean;
}

const StyledA = styled.a<{ noLink?: boolean }>`
  font-family: "header";
  color: white;
  text-align: center;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  /* text-decoration: ${props => (props.noLink ? `none` : `underline`)}; */
  cursor: ${props => (props.noLink ? `default` : `pointer`)};

  &:hover {
    color: white;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: auto;
  }
`;

function CovidResponseBanner(props: Props) {
  const { linkData, noLink } = props;

  return (
    <Container
      backgroundColor="lightBlue"
      margin="40px auto"
      tabletMargin="50px auto"
      height="fit-content"
      mobileHeight="auto"
      mobileMargin="40px auto"
    >
      <Container mobileWidth="90%" tabletMargin="0 auto">
        {noLink ? (
          <StyledA noLink href={linkData?.url ?? ""}>
            {linkData?.linktext}
          </StyledA>
        ) : (
          <StyledA
            href={linkData?.url ?? ""}
            target={linkData?.newwindow ? "_blank" : ""}
          >
            {linkData?.linktext}
          </StyledA>
        )}
      </Container>
    </Container>
  );
}

export default CovidResponseBanner;
